import {Component, Inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AgGridAngular} from "ag-grid-angular";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Game} from "../../../../../wildcard-dashboard-common/src/models/game";
import {ColDef} from "ag-grid-community";
import {GridReadyEvent, SelectionChangedEvent} from "@ag-grid-community/core";
import {Application} from "../../../wild-cloud/wild-cloud-models";
import {DashboardUser} from "../../../../../wildcard-dashboard-common/src/models/dashboard-user";

export interface GamePickerDialogData {
  user: DashboardUser,
  games: Game[];
  selectedIds: string[];
}

export interface GamePickerDialogResult {
  selectedIds: string[];
}

@Component({
  standalone: true,
  imports: [CommonModule, AgGridAngular, MatButtonModule],
  templateUrl: './game-picker.component.html',
  styleUrls: ['./game-picker.component.scss']
})
export class GamePickerComponent implements OnInit {
  colDefs: ColDef[] = [
    {
      field: 'icon',
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: 'name',
      headerName: 'App Name'
    }
  ];

  games: Game[];
  selectedIds: string[];

  constructor(public dialogRef: MatDialogRef<GamePickerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: GamePickerDialogData,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    console.log(this.data);

    this.games = [...this.data.games].sort((a, b) => a.name.localeCompare(b.name));
    this.selectedIds = [...this.data.selectedIds].sort();
  }

  get saveDisabled(): boolean {
    return this.selectedIds.length === 0;
  }

  get selectedCount() {
    return this.selectedIds.length || this.games.length;
  }

  onGridReady(params: GridReadyEvent<Application>) {
    const {api} = params;
    api.sizeColumnsToFit();

    if (this.selectedCount === this.games.length) {
      api.forEachNode(node => node.setSelected(true));
    } else {
      api.forEachNode(node => node.setSelected(this.selectedIds.includes(node.data.id)));
    }
  }

  onSelectionChanged(params: SelectionChangedEvent<DashboardUser>) {
    this.selectedIds = params.api.getSelectedRows().map(row => row.id).sort();
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    const selectedIds = this.selectedCount === this.games.length ? [] : this.selectedIds;

    const result: GamePickerDialogResult = {
      selectedIds,
    }

    this.dialogRef.close(result);
  }
}
