import {ApiWrapper} from "../services/dashboard-api.service";
import {
  AppsFlyerIntegration,
  Integration,
  IntegrationBase,
  IntegrationProvider
} from "../../../wildcard-dashboard-common/src/models/integrations";
import {
  AppStoreConnectIntegration,
  FirebaseIntegration,
  GooglePlayIntegration, JiraIntegration, UnityIntegration
} from "../data-model/integrations/types/applovin";

const dashboard = new ApiWrapper('dashboard');

const ShortcutProviders: Record<IntegrationProvider, (i: Partial<IntegrationBase>) => string> = {
  [IntegrationProvider.AppLovin]: i => "https://dash.applovin.com/o/mediation/ad_units/",
  [IntegrationProvider.AppsFlyer]: (i: AppsFlyerIntegration) => `https://hq1.appsflyer.com/unified-ltv/dashboard#appIds=${i.applications[0].afAppId}`,
  [IntegrationProvider.AppStoreConnect]: (i: AppStoreConnectIntegration) => `https://appstoreconnect.apple.com/apps/${i.appId}/distribution/ios`,
  [IntegrationProvider.Firebase]: (i: FirebaseIntegration) => `https://console.firebase.google.com/u/0/project/${i.displayName}/overview`,
  [IntegrationProvider.GooglePlay]: (i: GooglePlayIntegration) => `https://play.google.com/console/u/0/developers/6490540235565334154/`,
  [IntegrationProvider.Jira]: (i: JiraIntegration) => `https://wildcardgames.atlassian.net/jira/dashboards/last-visited`,
  [IntegrationProvider.Unity]: (i: UnityIntegration) => `https://cloud.unity.com/home/organizations/${i.organizationId}/projects/${i.projectId}`,
}

export class IntegrationsApi
{
  public static list(applicationId: string) {
    return dashboard.get<Integration[]>(`games/${applicationId}/integrations`);
  }

  public static async get<T=Integration>(applicationId: string, provider: IntegrationProvider) {
    return await dashboard.get<Integration>(`games/${applicationId}/integrations/${provider}`) as T;
  }

  public static create(applicationId: string, integration: Integration) {
    return dashboard.put<Integration>(`games/${applicationId}/integrations`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: integration
    });
  }

  public static update(applicationId: string, integration: Integration) {
    return dashboard.post<Integration>(`games/${applicationId}/integrations/${integration.provider}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: integration
    });
  }

  public static getShortcut(integration: Partial<IntegrationBase>): string {
    return ShortcutProviders[integration.provider](integration);
  }
}
