import {Injectable, OnInit} from '@angular/core';
import {from, map, Observable, of, ReplaySubject, switchMap, tap} from 'rxjs';
import {UsersAPI} from "../api/users";
import {DashboardUser, InviteDashboardUserRequest} from "../../../wildcard-dashboard-common/src/models/dashboard-user";
import {Utilities} from '../../../wildcard-dashboard-common/src/models/utilities';
import {AuthorizationService} from "../authorization.service";

@Injectable({
  providedIn: 'root'
})
export class UserService
{
  private _user: DashboardUser;
  private _userId: string;
  private _accessToken: string;

  /**
   * Constructor
   */
  constructor(private authorizationService: AuthorizationService) {
    this.list().then();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  setAccessToken(value: string) {
    this._accessToken = value;
  }

  /**
   * Get the current logged in user data
   */
  async getCurrentUser(): Promise<DashboardUser> {
    if (!this._user) {
      this._userId = this.authorizationService.currentAuthenticatedUser.userId;
      this._user = await UsersAPI.getUser(this.authorizationService.currentAuthenticatedUser.userId);
    }
    return this._user;
  }

  /**
   * Update the user
   *
   * @param user
   * @param props
   */
  async update(user: DashboardUser, props: Partial<DashboardUser>)
  {
    return UsersAPI.updateUser(user, props);
  }

  cachedUsers: {string: DashboardUser};

  loadingUsers = false;
  async list(refresh: boolean = false): Promise<DashboardUser[]>
  {
    while (this.loadingUsers) {
      await Utilities.sleep(50);
    }

    await this.authorizationService.waitForAuthorization();

    if (!this.cachedUsers) {
      console.log('Loading users');
      this.loadingUsers = true;

      try {
        const cache: any = {};
        const users = await UsersAPI.listUsers();

        users.forEach(user => cache[user.id] = user);

        this.cachedUsers = cache;
        // console.warn('Cache reloaded', cache);
      } finally {
        this.loadingUsers = false;
      }
    }

    return Object.values(this.cachedUsers);
  }

  async getUser(userId: string) {
    if (!this.cachedUsers || !this.cachedUsers[userId]) {
      await this.list();
    }

    return this.cachedUsers[userId];
  }

  async invite(request: InviteDashboardUserRequest): Promise<DashboardUser> {
    return UsersAPI.inviteUser(request);
  }

  async disable(user: DashboardUser) {
    return UsersAPI.disableUser(user);
  }

  async enable(user: DashboardUser) {
    return UsersAPI.enableUser(user);
  }
}
