<div class="user-name">
  <button mat-fa class="profile-button" [matMenuTriggerFor]="userMenu">
    <mat-icon *ngIf="!iconUrl">account_circle</mat-icon>
    <img [ngSrc]="iconUrl" *ngIf="iconUrl" width="60" height="60"  style="border-radius: 30px; border: 2px solid rgba(255,255,255,0.5); drop-shadow(5px 5px, black)">
  </button>
  <mat-menu #userMenu="matMenu">
    <div style="font-size: small; width: 100%; text-align: center; color: darkgray"> {{userName}} </div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="reportIssue()">
      <mat-icon>warning</mat-icon>
      <span>Report issue</span>
    </button>
    <button mat-menu-item (click)="showProfile()">
      <mat-icon>manage_accounts</mat-icon>
      <span>Profile</span>
    </button>

    <button mat-menu-item *ngIf="isAdmin" (click)="manageUsers()">
      <mat-icon>groups</mat-icon>
      <span>Manage Team</span>
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item (click)="logoutUser()">
      <mat-icon>logout</mat-icon>
      <span>Sign out</span>
    </button>
  </mat-menu>
</div>
