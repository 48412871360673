import {Component, Input, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {Application} from "../../../wild-cloud/wild-cloud-models";
import {IntegrationsApi} from "../../../api/integrations";
import {IntegrationBase} from "../../../../../wildcard-dashboard-common/src/models/integrations";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-shortcuts',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, MatTooltipModule, MatButtonModule, MatIconModule],
  templateUrl: './app-shortcuts.component.html',
  styleUrls: ['./app-shortcuts.component.scss']
})
export class AppShortcutsComponent implements OnInit{
  @Input() game: Application;

  integrations: Partial<IntegrationBase>[];

  constructor() {}

  async ngOnInit()
  {
    if (!this.game) {
      console.warn('No game to load. Bailing');
      return;
    }

    this.integrations = await IntegrationsApi.list(this.game.id);
  }

  jumpToIntegration(integration: Partial<IntegrationBase>) {
    const url = IntegrationsApi.getShortcut(integration);

    window.open(url, '_blank');
  }
}
