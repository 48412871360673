<div *ngIf="loading">
    <app-spinner></app-spinner>
</div>
<div style="width: 100%; display: flex; flex-direction: row; gap: 5px; align-items: center; padding-left: 10px; padding-right: 10px">
  <mat-checkbox [ngModel]="showInactiveUsers" (change)="toggleInactiveUsers()">Show Inactive</mat-checkbox>
  <div style="flex-grow: 1"></div>
  <div style="display: flex; flex-direction: row; padding-bottom: 5px; padding-left: 5px; cursor:pointer; align-items: center;"
       [matMenuTriggerFor]="gameMenu" class="selector-button">
    <div *ngIf="selectedGame" style="display: flex; flex-direction: row; align-items: center">
      <app-game-icon [game]="selectedGame" class="nav-icon"></app-game-icon>
      <div style="padding-left: 5px;">{{ selectedGame.name }}</div>
    </div>
    <div *ngIf="!selectedGame">
      <div style="padding-left: 5px;">All Games</div>
    </div>
    <mat-icon>expand_more</mat-icon>
  </div>
  <mat-menu #gameMenu="matMenu">
    <button mat-menu-item
            style="vertical-align: middle"
            (click)="filterGame(null)">
      <span style="padding-left: 5px;">All Games</span>
    </button>
    <button mat-menu-item
            style="vertical-align: middle"
            (click)="filterGame(game)" *ngFor="let game of games">
      <app-game-icon [game]="game" class="nav-icon"></app-game-icon>
      <span style="padding-left: 5px;">{{ game.name }}</span>
    </button>
  </mat-menu>
  <div style="flex-grow: 1"></div>
  <button mat-icon-button><mat-icon>search</mat-icon></button>
  <button mat-flat-button color="primary" style="width: 100px;" (click)="inviteUser()">Invite</button>
</div>
<div style="width: 100%; height:100%; display: flex; flex-direction: row">
  <div style="margin-top: 10px; margin-left: 10px; margin-right: 20px;">
    <div style="font-weight: bold">Users</div>
    <div>All</div>
    <div *ngFor="let role of roles">{{role}}</div>
  </div>
  <ag-grid-angular
      style="width: 100%; height: 100%;"
      class="ag-theme-quartz"
      [rowData]="users"
      [columnDefs]="colDefs"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
      (rowClicked)="editUser($event)"
      (newColumnsLoaded)="onColumnsLoaded($event)"
  ></ag-grid-angular>
</div>
