<div *ngIf="isAllApps">
  All
</div>
<div *ngIf="!isAllApps" style="height: 100%; display: flex; flex-direction: row; gap:2px; align-items: center">
  <img *ngFor="let appId of user.applications"
       style="border-radius: 4px;"
       [ngSrc]="getIconUrl(appId)"
       width="24"
       height="24"
       [matTooltip]="getAppName(appId)"
       [alt]="getAppName(appId)">
</div>
