import {Component, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {UserService} from "../../../services/user.service";
import {firstValueFrom} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ApplicationsService} from "../../../core/applications.service";
import {
  AllDashboardRoleIds,
  DashboardRoleId,
  DashboardUser
} from "../../../../../wildcard-dashboard-common/src/models/dashboard-user";
import {Application} from "../../../wild-cloud/wild-cloud-models";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule} from "@angular/forms";
import {MatChipsModule} from "@angular/material/chips";
import {MatListModule} from "@angular/material/list";
import {Utilities} from "../../../../../wildcard-dashboard-common/src/models/utilities";
import {MatDialog} from "@angular/material/dialog";
import {GamePickerComponent, GamePickerDialogResult} from "../../ui/game-picker/game-picker.component";
import {SpinnerComponent} from "../../ui/spinner/spinner.component";
import {AlertService} from "../../ui/alert.service";
import {UserApplicationsEditorComponent} from "../user-applications-editor/user-applications-editor.component";
import {UserRolesEditorComponent} from "../user-roles-editor/user-roles-editor.component";

@Component({
  selector: 'app-dashboard-user-editor',
  templateUrl: './dashboard-user-editor.component.html',
  styleUrls: ['./dashboard-user-editor.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule, MatCheckboxModule, MatButtonModule, FormsModule, MatChipsModule, MatListModule, SpinnerComponent, NgOptimizedImage, UserApplicationsEditorComponent, UserRolesEditorComponent]
})
export class DashboardUserEditorComponent implements OnInit {
  currentUser: DashboardUser;
  user: DashboardUser;
  originalUser: DashboardUser;
  games: Application[];
  isLoading = true;

  constructor(private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private applicationService: ApplicationsService,
              private router: Router,
              private alertService: AlertService,
              ) { }

  async ngOnInit() {
    const {userId} = await firstValueFrom(this.activatedRoute.params);

    const user = await this.userService.getUser(userId);
    const games = await this.applicationService.listApplications();
    const currentUser = await this.userService.getCurrentUser();

    this.user = user;
    this.originalUser = Utilities.copy(user);
    this.games = games;
    this.currentUser = currentUser;

    console.log('applications', this.user.applications);

    this.isLoading = false;
  }

  get isSaveDisabled() {
    return JSON.stringify(this.originalUser) === JSON.stringify(this.user);
  }

  get isCurrentUser() {
    return this.currentUser.id === this.user.id;
  }

  async cancel() {
    await this.router.navigate(['..'], {relativeTo: this.activatedRoute});
  }

  async save() {
    // the only things that can change are roles or apps
    try {
      this.isLoading = true;

      const props: Partial<DashboardUser> = {};

      if (JSON.stringify(this.user.roles) !== JSON.stringify(this.originalUser.roles)) {
        props.roles = this.user.roles;
      }

      if (JSON.stringify(this.user.applications) !== JSON.stringify(this.originalUser.applications)) {
        props.applications = this.user.applications;
      }

      console.log(props);

      await this.userService.update(this.user, props);

      await this.router.navigate(['..'], {relativeTo: this.activatedRoute});
    } catch (err) {
      await this.alertService.showError(err);
    } finally {
      this.isLoading = false;
    }
  }

  async disableUser() {
    try {
      this.isLoading = true;
      await this.userService.disable(this.user);
      await this.router.navigate(['..'], {relativeTo: this.activatedRoute});
    } catch (err) {
      await this.alertService.showError(err);
    } finally {
      this.isLoading = false;
    }
  }

  async enableUser() {
    try {
      this.isLoading = true;
      await this.userService.enable(this.user);
      await this.router.navigate(['..'], {relativeTo: this.activatedRoute});
    } catch (err) {
      await this.alertService.showError(err);
    } finally {
      this.isLoading = false;
    }
  }
}
