<div style="display: flex; flex-direction: column; width: 100%">
    <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between; align-items: center">
        <h2>Apps</h2>
        <button mat-stroked-button (click)="manageApps()">Manage apps</button>
    </div>
    <div>This user will have access to the apps you select.</div>
    <div style="display: flex; flex-direction: row; margin-top: 10px; margin-bottom: 10px; gap: 5px">
        <div *ngIf="isAllApps" class="app-chip">All Apps</div>
        <div *ngFor="let app of selectedApps" class="app-chip">
            <img width="24" height="24" [ngSrc]="app.iconUrl" [alt]="app.name" style="margin-right: 5px; border-radius: 4px">
            <div>{{app.name}}</div>
        </div>
    </div>
</div>
