import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AuthGuard} from './auth/auth-guard';
import {AppComponent} from './app.component';

import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from "@angular/material/select";
import {MatMenuModule} from '@angular/material/menu';

import {FlexLayoutModule} from '@angular/flex-layout';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AmplifyAuthenticatorModule} from '@aws-amplify/ui-angular';
import {HttpClientModule} from '@angular/common/http';

// Internal
import {UpcomingEventsComponent} from './upcoming-events/upcoming-events.component';
import {NewsfeedComponent} from './newsfeed/newsfeed.component';
import {CrossPromotionComponent} from './cross-promotion/cross-promotion.component';
import {NavigationComponent} from './layouts/navigation/navigation.component';
import {LoginComponent} from './auth/login/login.component';
import {SecureComponent} from './layouts/secure/secure.component';
import {PublicComponent} from './layouts/public/public.component';
import {SettingsComponent} from './settings/settings.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {StorageModule} from '@ngx-pwa/local-storage';

import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";

import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {CdkDetailRowDirective} from './directives/cdk-detail-row.directive';
import {DirtyDeactivateGuard} from './guards/dirty-deactivate-guard';
import {DashboardsModule} from "./modules/dashboards/dashboards.module";
import {CommonUiModule} from "./modules/ui/common-ui.module";
import {NgOptimizedImage} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {SpinnerComponent} from "./modules/ui/spinner/spinner.component";
import {MarkdownModule} from "ngx-markdown";
import {MatSortModule} from "@angular/material/sort";
import {AppShortcutsComponent} from "./modules/integrations/app-shortcuts/app-shortcuts.component";

@NgModule({
  declarations: [
    AppComponent,
    UpcomingEventsComponent,
    NewsfeedComponent,
    CrossPromotionComponent,
    NavigationComponent,
    LoginComponent,
    SecureComponent,
    PublicComponent,
    SettingsComponent,
    CdkDetailRowDirective,
  ],
  imports: [
    // Internal Modules
    BrowserModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    BrowserAnimationsModule,
    CdkTableModule,
    CdkTreeModule,
    HttpClientModule,
    FlexLayoutModule,

    // Material Modules
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,

    // External Modules
    ScrollingModule,
    StorageModule.forRoot({IDBNoWrap: true}),
    NgxDaterangepickerMd.forRoot(),
    MarkdownModule.forRoot(),

    // Internal Modules
    DashboardsModule,
    CommonUiModule,
    NgOptimizedImage,

    SpinnerComponent,
    MatSortModule,
    AppShortcutsComponent,
  ],
  providers: [
    AuthGuard,
    DirtyDeactivateGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
