import { Injectable } from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate} from '@angular/router';
import * as Auth from '@aws-amplify/auth';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      try {
        await Auth.getCurrentUser();
        return true;
      } catch (err) {
        return this.router.parseUrl('login');
      }
    }
}

// @Injectable()
// export class RoleGuard implements CanActivate {
//   constructor(private router: Router,
//               private authorizationService: AuthorizationService) { }
//
//   async canActivate(route: ActivatedRouteSnapshot,
//                     state: RouterStateSnapshot) {
//     try {
//       const user = await Auth.getCurrentUser();
//     } catch (err) {
//       return this.router.parseUrl('/');
//     }
//   }
// }
