<mat-dialog-content>
  <h2>Invite User</h2>
  <div class="text-field-container">
    <mat-label>Full Name</mat-label>
    <input matInput class="text-input" [(ngModel)]="inviteRequest.name">
  </div>
  <div class="text-field-container">
    <mat-label>Email</mat-label>
    <input matInput class="text-input" [(ngModel)]="inviteRequest.email">
  </div>
  <mat-divider></mat-divider>
  <user-roles-editor [(roles)]="inviteRequest.roles"></user-roles-editor>
  <mat-divider></mat-divider>
  <user-applications-editor [(selectedApplications)]="inviteRequest.applications" [games]="data.games"></user-applications-editor>
</mat-dialog-content>
<mat-dialog-actions style="width: 100%; display: flex; flex-direction: row; justify-content: right; gap: 10px;">
  <button mat-flat-button (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="invite()" [disabled]="inviteDisabled">Invite</button>
</mat-dialog-actions>
