import {Injectable} from '@angular/core';

import {S3, S3Client, PutObjectCommand} from '@aws-sdk/client-s3';
import {AuthorizationService} from "./authorization.service";
import {DashboardApiService} from "./services/dashboard-api.service";
import {AthenaService, Records} from "./modules/data/athena.service";

const gitVersion = require('../git-version.json');
const version = require('../version.json');

import { Cache } from 'aws-amplify/utils';

const resultCache = Cache.createInstance({
  keyPrefix: 'wc-sql-',
  capacityInBytes: 1024*1024,
  itemMaxSize: 128*1024,
  defaultTTL: 10 * 60 * 1000,
  defaultPriority: 5,
  warningThreshold: 0.8,
  storage: window.sessionStorage,
});

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  s3Bucket: S3;
  s3Client: S3Client;

  constructor(private authorizationService: AuthorizationService,
              private dashboardApiService: DashboardApiService,
              private athenaService: AthenaService) {
  }

  async initialize() {
    if (!this.s3Bucket) {
      const credentials = await this.authorizationService.currentCredentials;

      this.s3Bucket = new S3([{credentials, params: {Bucket: 'wc-news'}}]);
      this.s3Client = new S3Client({credentials});
    }
  }

  //
  // Public methods
  //
  async saveFile(file: File, name: string): Promise<string> {
    const bucket = 'wc-news';
    const params = new PutObjectCommand({
      ACL: 'public-read',
      CacheControl: 'max-age=2592000',
      Key: name,
      ContentType: file.type,
      Body: file,
      Bucket: bucket,
    });

    return this.s3Client.send(params)
      .then(data => {
        console.log(data);
        return `https://${bucket}.s3.amazonaws.com/${name}`;
      });
  }

  async runReportingQuery(sql: string, opts?) {
    opts = opts || {};

    await this.initialize();

    const options = {body: {sql}}
    const response = await this.dashboardApiService.reportingQuery.post<any>('', options).catch(err => {
      console.error(`FAILED query: ${sql}`);
      console.log(err);
      console.error(err);

      throw err;
    });

    console.warn('query');
    console.warn(sql);
    console.warn(JSON.stringify(response));

    let results = response.data[0];

    if (opts.returnLastResultIndex) {
      results = results[results.length - 1];
    }

    return results;
  }

  async runDataWarehouseQuery(sql: string, allowCaching = false) {
    if (allowCaching) {
      const records = await resultCache.getItem(sql) as Records;

      if (records) {
        console.log('cache hit');
        return records;
      }
    }

    console.log('cache miss');

    try {
      console.log(`query:\n\n${sql}`);
      const q = await this.athenaService.startQuery(sql);
      const status = await q.waitForCompletion();

      const result = await q.getResult();

      await resultCache.setItem(sql, result);

      return result;
    }
    catch (err) {
      console.error(err);
      throw err;
    }
  }
  getBuildConfig() {
    return gitVersion;
  }

  getAppVersion() {
    return version;
  }
}
