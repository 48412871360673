// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  amplifyConfig: {
    Auth: {
      Cognito: {
        identityPoolId: 'us-west-2:06924d52-3de8-4ed8-878d-c7aa71243334',
        userPoolId: 'us-west-2_7NssHOXDa',
        userPoolClientId: 'c0ckqvvm7800elcjtvdcei9uc',
      }
    },

    API: {
      REST: {
        quicksightDashboard : {
          endpoint: 'https://dev.wildcardmobile.io/dashboard/quicksight',
          region: 'us-west-2',
        },
        data: {
          endpoint: 'https://dev.wildcardmobile.io/dashboard/data',
          region: 'us-west-2',
        },
        dashboard: {
          endpoint: 'https://dev.wildcardmobile.io/dashboard/',
          region: 'us-west-2',
        },
        users: {
          endpoint: 'https://dev.wildcardmobile.io/dashboard/users',
          region: 'us-west-2',
        },
        roles: {
          endpoint: 'https://dev.wildcardmobile.io/dashboard/roles',
          region: 'us-west-2',
        },
        campaigns: {
          endpoint: 'https://dev.wildcardmobile.io/dashboard/campaigns',
          region: 'us-west-2',
        },
        reportingQuery: {
          endpoint: 'https://dev.wildcardmobile.io/dashboard/query/reporting',
          region: 'us-west-2',
        },
        athena: {
          endpoint: 'https://dev.wildcardmobile.io/dashboard/query/athena',
          region: 'us-west-2',
        },
        dataWarehouseQuery: {
          endpoint: 'https://dev.wildcardmobile.io/dashboard/query/datawarehouse',
          region: 'us-west-2',
        },
        'wildcloud_dev': {
          endpoint: 'https://api.dev.wildcloud.io/admin',
          region: 'us-west-2',
        },
        'wildcloud_live': {
          endpoint: 'https://api.live.wildcloud.io/admin',
          region: 'us-west-2',
        },
        'data-connector-google': {
          endpoint: 'https://dev.wildcardmobile.io/connectors/google/',
          region: 'us-west-2',
        },
        'data-connector-appstore': {
          endpoint: 'https://dev.wildcardmobile.io/connectors/appstore-connect/',
          region: 'us-west-2',
        },
        'data-connector-applovin': {
          endpoint: 'https://dev.wildcardmobile.io/connectors/applovin/',
          region: 'us-west-2',
        },
        'data-connector-appsflyer': {
          endpoint: 'https://dev.wildcardmobile.io/connectors/appsflyer/',
          region: 'us-west-2',
        },
        'data-connector': {
          endpoint: 'https://dev.wildcardmobile.io/connectors/',
          region: 'us-west-2',
        },
    },
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
