import * as API from '@aws-amplify/api-rest';

import {
  DashboardUser, GetDashboardUserRequest, GetDashboardUserResponse,
  InviteDashboardUserRequest
} from "../../../wildcard-dashboard-common/src/models/dashboard-user";
import {ApiWrapper} from "../services/dashboard-api.service";

const users = new ApiWrapper('dashboard');

export class UsersAPI {
  public static async inviteUser(request: InviteDashboardUserRequest): Promise<DashboardUser> {
    console.warn('inviteUser', request);
    return users.put('users/invite', {body: request as any});
  }

  public static async getUser(id: string): Promise<DashboardUser> {
    if (!id) {
      throw new Error('Missing id');
    }

    return users.get(`users/${id}`);
  }

  public static async listUsers(): Promise<DashboardUser[]> {
    return users.get(`users`);
  }

  public static async updateUser(user: DashboardUser, properties : Partial<DashboardUser>) {
    return await users.post(`users/${user.id}`, {body: properties as any}) as DashboardUser;
  }

  public static async disableUser(user: DashboardUser) {
    return await users.post(`users/${user.id}/disable`, {});
  }

  public static async enableUser(user: DashboardUser) {
    return await users.post(`users/${user.id}/enable`, {});
  }

}
