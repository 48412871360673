<div style="width: 100%; height: 100%; margin:10px; display: flex; flex-direction: column">
  <h2 *ngIf="data.user">App Access for {{ data.user.name }}</h2>
  <div>This user will have access to the apps you select.</div>
  <div style="display: flex; flex-direction: row">
    <h2>Apps ({{selectedCount}})</h2>
    <div style="flex-grow: 1"></div>
    <!-- filter box? -->
  </div>
  <ag-grid-angular
      style="width: 100%; overflow: scroll; flex-grow: 1"
      class="ag-theme-quartz"
      rowSelection="multiple"
      suppressCellFocus="true"
      suppressRowClickSelection="true"
      [columnDefs]="colDefs"
      [rowData]="games"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged($event)"
  >
  </ag-grid-angular>
  <div style="width: 100%; display: flex; flex-direction: row; justify-content: right; gap: 10px;">
    <button mat-stroked-button class="action-button" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" class="action-button" [disabled]="saveDisabled" (click)="save()">Done</button>
  </div>
</div>
