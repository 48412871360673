import {Component, Inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatInputModule} from "@angular/material/input";
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonModule} from "@angular/material/button";
import {
  DashboardUser,
  InviteDashboardUserRequest
} from "../../../../../wildcard-dashboard-common/src/models/dashboard-user";
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {UserRolesEditorComponent} from "../user-roles-editor/user-roles-editor.component";
import {UserApplicationsEditorComponent} from "../user-applications-editor/user-applications-editor.component";
import {Application} from "../../../wild-cloud/wild-cloud-models";
import {FormsModule} from "@angular/forms";

export interface InviteUserDialogData {
  games: Application[];
  users: DashboardUser[];
}

const EmailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

@Component({
  standalone: true,
  imports: [CommonModule, MatInputModule, MatDividerModule, MatButtonModule, MatDialogModule, UserRolesEditorComponent, UserApplicationsEditorComponent, FormsModule],
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialog {
  inviteRequest: Partial<InviteDashboardUserRequest> = {
    applications: [],
    roles: [],
  };

  constructor(public dialogRef: MatDialogRef<InviteUserDialog>,
              @Inject(MAT_DIALOG_DATA) public data: InviteUserDialogData,
              ) { }

  emailExists(email: string) {
    return !!this.data.users.find(u => u.email === email);
  }

  get isNameValid() {
    const name = (this.inviteRequest.name || '').trim();

    let isValid = name.length > 10;
    isValid = isValid && name.includes(' ');

    return isValid;
  }

  get isEmailValid() {
    const {email} = this.inviteRequest;

    let isValid = EmailRegEx.test(email);
    isValid = isValid &&  !this.emailExists(email);

    return isValid;
  }

  get inviteDisabled() {
    return !this.isEmailValid || !this.isNameValid || this.inviteRequest.roles.length === 0;
  }

  invite() {
    this.dialogRef.close(this.inviteRequest);
  }

  cancel() {
    this.dialogRef.close();
  }
}
