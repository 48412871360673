<h2>Roles</h2>
<div style="width: 100%; display: flex; flex-direction: row; justify-content: left; flex-wrap: wrap">
<!--    <div style="display: flex; flex-direction: column" *ngFor="let roles of toggleGroups">-->
<!--        <mat-checkbox-->
<!--                *ngFor="let role of roles"-->
<!--                [ngModel]="isRoleSelected(role)"-->
<!--                (change)="toggleRole(role)"-->
<!--                [disabled]="!isRoleEnabled(role)">-->
<!--            {{ role }}-->
<!--        </mat-checkbox>-->
<!--    </div>-->
  <mat-checkbox
      class="role-checkbox"
      *ngFor="let role of toggleList"
      [ngModel]="isRoleSelected(role)"
      (change)="toggleRole(role)"
      [disabled]="!isRoleEnabled(role)">
    {{ role }}
  </mat-checkbox>
</div>
