<app-spinner *ngIf="isLoading"></app-spinner>
<div *ngIf="user" style="width: 100%; border-radius: 10px; padding: 20px 5%;">
  <div style="display: flex; flex-direction: row;">
    <div [matTooltip]="user.id">Full Name: {{user.name}}</div>
    <div style="flex-grow: 1"></div>
    <button mat-flat-button color="warn" (click)="disableUser()" class="action-button" *ngIf="user.status === 'active'" [disabled]="isCurrentUser">Suspend</button>
    <button mat-flat-button color="warn" (click)="enableUser()" class="action-button" *ngIf="user.status === 'suspended'" [disabled]="isCurrentUser">Enable</button>
  </div>
  <mat-divider></mat-divider>
  <div>
    <div>Email: {{user.email}}</div>
  </div>
  <mat-divider></mat-divider>
  <user-roles-editor [(roles)]="user.roles"></user-roles-editor>
  <mat-divider></mat-divider>
  <user-applications-editor [games]="games" [(selectedApplications)]="user.applications" [user]="user"></user-applications-editor>
  <div style="width: 100%; display: flex; flex-direction: row; justify-content: center; gap: 20px;">
    <button mat-stroked-button (click)="cancel()" class="action-button">Close</button>
    <button mat-flat-button color="primary" (click)="save()" class="action-button" [disabled]="isSaveDisabled">Save</button>
  </div>
</div>
