import { Injectable } from '@angular/core';
import {UserService} from "./user.service";

// this is a global
declare var gtag : any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor() {}

  trackEvent(eventName: string, eventDetails: string, eventCategory: string) {
    gtag('event', eventName, {
      // event Type - example: 'SCROLL_TO_TOP_CLICKED'
      'event_category': eventCategory,
      // the label that will show up in the dashboard as the events name
      'event_label': eventName,
      // a short description of what happened
      'value': eventDetails,
      // userId: this.userService.user.email,
    })
  }

  trackApi(apiName: string, method: string, path: string, durationMS: number) {
    gtag('event', 'api_request', {
      'event_category': 'API',
      'event_label': 'api_request',
      source: apiName,
      method,
      resource: `${method}:${path}`,
      engagement_time_msec: durationMS,
      // userId: this.userService.user.email,
    })
  }
}
