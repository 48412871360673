import {Component, Input, OnInit} from '@angular/core';
import {Application} from '../../../wild-cloud/wild-cloud-models';
import {MatIconModule} from "@angular/material/icon";
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-game-icon',
  templateUrl: './game-icon.component.html',
  styleUrls: ['./game-icon.component.css'],
  standalone: true,
  imports: [MatIconModule, NgOptimizedImage]
})
export class GameIconComponent implements OnInit {

  @Input() game: Application;
  @Input() size = 'small';

  constructor() { }

  ngOnInit() {
  }

  get class(): string {
    let value = 'icon-small';

    if (this.size) {
      value = `icon-${this.size}`;
    }

    return value;
  }

  get pixelSize(): number {
    switch (this.size) {
      case 'tiny':
        return 16;
      case 'small':
        return 32;
      case 'medium':
        return 64;
      case 'large':
        return 128;
    }

    // default
    return 32;
  }

  get height(): number { return this.pixelSize; }
  get width(): number { return this.pixelSize; }

  get iconUrl(): string {
    return this.game.iconUrl || '/assets/img/application-placeholder2-512.png';
  }
}
