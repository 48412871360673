import {Utilities} from "./utilities";

export enum IntegrationProvider {
  AppLovin = 'applovin',
  AppsFlyer = 'appsflyer',
  AppStoreConnect = 'appstore-connect',
  Firebase = 'firebase',
  Jira = 'jira',
  GooglePlay = 'googleplay',
  Unity = 'unity',
}

interface IntegrationDefinition {
  provider: IntegrationProvider,
  name: string;
}

export interface IntegrationBase {
  applicationId: string;
  provider: IntegrationProvider;
}

export type Integration = Partial<IntegrationBase>;

export const INTEGRATIONS : IntegrationDefinition[] = [
  {provider: IntegrationProvider.AppLovin,        name: 'AppLovin'},
  {provider: IntegrationProvider.AppsFlyer,       name: 'AppsFlyer'},
  {provider: IntegrationProvider.AppStoreConnect, name: 'Apple App Store'},
  {provider: IntegrationProvider.Jira,            name: 'Jira'},
  {provider: IntegrationProvider.GooglePlay,      name: 'Google Play Store'},
  {provider: IntegrationProvider.Firebase,        name: 'Google Firebase'},
  {provider: IntegrationProvider.Unity,           name: 'Unity'},
];

export const AllIntegrationProviders = Utilities.getEnumValues<IntegrationProvider>(IntegrationProvider);

export type IntegrationPlatform = 'Android' | 'iOS';

export interface AppsFlyerApplication {
  afAppId: string;
  platform: IntegrationPlatform;
  startDate: string;
}

export interface AppsFlyerIntegration extends Integration {
  applications: AppsFlyerApplication[];
}
