import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {DashboardListComponent} from './dashboard-list/dashboard-list.component';
import {DashboardViewerComponent} from './dashboard-viewer/dashboard-viewer.component';
import {MoveDashboardDialogComponent} from './move-dashboard-dialog/move-dashboard-dialog.component';
import {QuicksightDashboardComponent} from './quicksight-dashboard/quicksight-dashboard.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {CommonUiModule} from '../ui/common-ui.module';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SpinnerComponent} from "../ui/spinner/spinner.component";
import {CreateDashboardDialogComponent} from "./create-dashboard-dialog/create-dashboard-dialog.component";
import {CdkOverlayOrigin} from "@angular/cdk/overlay";
import {FileTicketButtonComponent} from "../ui/file-ticket-button/file-ticket-button.component";
import {MatSortModule} from "@angular/material/sort";

@NgModule({
  declarations: [
    DashboardListComponent,
    DashboardViewerComponent,
    MoveDashboardDialogComponent,
  ],
  exports: [
    DashboardListComponent,
    DashboardViewerComponent,
    MoveDashboardDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,

    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,

    // Internal Components
    SpinnerComponent,
    CreateDashboardDialogComponent,

    CommonUiModule,
    CdkOverlayOrigin,
    FileTicketButtonComponent,
    QuicksightDashboardComponent,
    MatSortModule,
  ]
})
export class DashboardsModule {
}
