import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../services/user.service";
import {
  AllDashboardRoleIds,
  DashboardRoleId,
  DashboardUser,
} from "../../../../../wildcard-dashboard-common/src/models/dashboard-user";
import {GridApi, GridReadyEvent, ValueFormatterParams, ValueGetterParams} from "@ag-grid-community/core";
import {ApplicationsService} from "../../../core/applications.service";
import {Application, Comment} from "../../../wild-cloud/wild-cloud-models";
import {CommonModule} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {AgGridModule} from "ag-grid-angular";
import {MatButtonModule} from "@angular/material/button";
import {SpinnerComponent} from "../../ui/spinner/spinner.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GameIconComponent} from "../../games/game-icon/game-icon.component";
import {Router} from "@angular/router";
import {ColDef} from "ag-grid-community";
import {ApplicationsCellRendererComponent} from "../applications-cell-renderer/applications-cell-renderer.component";
import {AlertService} from "../../ui/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {InviteUserDialog} from "../invite-user-dialog/invite-user-dialog.component";
import {firstValueFrom} from "rxjs";

const InactiveFilter = {
  filterType: "text",
  operator: "OR",
  conditions: [
    {filterType: "text", type: "contains", filter: "active"},
    {filterType: "text", type: "contains", filter: "invited"}
  ]
};

@Component({
  selector: 'app-dashboard-user-list',
  templateUrl: './dashboard-user-list.component.html',
  styleUrls: ['./dashboard-user-list.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    AgGridModule,
    MatButtonModule,
    SpinnerComponent,
    MatCheckboxModule,
    GameIconComponent,
  ]
})
export class DashboardUserListComponent implements OnInit {
  loading = true;
  colDefs : ColDef<DashboardUser>[];

  showInactiveUsers: boolean = false;
  gridApi!: GridApi;
  users: DashboardUser[];
  displayedColumns = ['avatar', 'name', 'id', 'status', 'roles', 'created'];
  roles: DashboardRoleId[];
  games: Application[];
  selectedGame: Application;

  constructor(private userService: UserService,
              private applicationService: ApplicationsService,
              private router: Router,
              private alertService: AlertService,
              private dialog: MatDialog) {}

  async ngOnInit() {
    const [users, games] = await Promise.all([
      this.userService.list(),
      this.applicationService.listApplications(),
    ]);

    this.roles = AllDashboardRoleIds;
    this.games = games.sort((a,b) => a.name.localeCompare(b.name));

    this.initColumns();

    this.users = users.sort((a,b) => a.email.localeCompare(b.email));

    this.loading = false;

    // await this.updateStatusFilter();
  }

  initColumns() {
    this.colDefs = [
      {
        field: 'name',
        headerName: 'Name',
        // checkboxSelection: true,
        // headerCheckboxSelection: true,
      },
      {
        field: 'email',
        headerName: 'Email',
      },
      {
        field: 'status',
        filter: true,
      },
      {
        field: 'roles',
        filter: true,
        autoHeight: true,
        valueFormatter: params => params.data.roles.join(', '),
      },
      {
        field: 'applications',
        filter: true,
        cellRenderer: ApplicationsCellRendererComponent,
        cellRendererParams: {
          games: this.games
        },
      },
    ];
  }

  async toggleInactiveUsers() {
    this.showInactiveUsers = !this.showInactiveUsers;

    return this.updateStatusFilter();
  }

  async updateStatusFilter() {
    const value = this.showInactiveUsers;
    const filter = value ? null : InactiveFilter;

    console.log('filter', filter);

    await this.gridApi.setColumnFilterModel('status', filter);
    this.gridApi.onFilterChanged();
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  async onFilterChanged(evt: any) {
    console.log(evt);

    console.log('getColumnFilterModel', this.gridApi.getColumnFilterModel('status'));
  }

  onColumnsLoaded(evt) {
    console.log(evt);
    evt.api.autoSizeColumns();
    evt.api.sizeColumnsToFit();

    this.updateStatusFilter().then();
  }

  async inviteUser() {
    const dialog = this.dialog.open(InviteUserDialog, {
      width: '600px',
      height: '600px',
      data: {
        games: this.games,
        users: this.users,
      }
    });

    const request = await firstValueFrom(dialog.afterClosed());

    if (request) {
      try {
        this.loading = true;
        const user = await this.userService.invite(request);
        this.users.push(user);
      } catch (error) {
        await this.alertService.showError(error.message);
      } finally {
        this.loading = false;
      }
    }
  }

  async editUser(evt: any) {
    const user: DashboardUser = evt.data;

    await this.router.navigate([`/users/${user.id}`]);
  }

  async filterGame(game: Application) {
    this.selectedGame = game;

    let filter : any;

    if (game) {
      filter = {
        filterType: 'text',
        type: 'contains',
        filter: game.id,
      };
    }

    await this.gridApi.setColumnFilterModel('applications', filter);
    this.gridApi.onFilterChanged();
  }
}
