import {Utilities} from "./utilities";

///////////////////////////////////////////
//
// Action Type
//

export enum ActionType {
  Admin = 'admin',
  Create = 'create',
  Update = 'update',
  Read = 'read',
  List = 'list',
}

export const AllActionTypes = Utilities.getEnumValues<ActionType>(ActionType);
export const ActionTypePrecedence: ActionType[] = [ActionType.Admin, ActionType.Create, ActionType.Update, ActionType.List, ActionType.Read];


///////////////////////////////////////////
//
// Resource Type
//

export enum ResourceType {
  Finance = 'finance',
  Growth = 'growth',
  Users = 'users',
  Games = 'games',
  GameIntegrations = 'games::integrations',
  GameExperiments = 'games::experiments',
  GameVariables = 'games::variables',
  Data = 'data',
}

export const AllResourceTypes = Utilities.getEnumValues<ResourceType>(ResourceType);

///////////////////////////////////////////
//
// Roles
//

export enum DashboardRoleId {
  Admin = 'Admin',
  Finance = 'Finance',
  Growth = 'Growth',
  Analyst = 'Analyst',
  Developer = 'Developer',
  AppManager = 'AppManager',
  QA = 'QualityAssurance',
  CS = 'CustomerSupport',
}

export const AllDashboardRoleIds = Utilities.getEnumValues<DashboardRoleId>(DashboardRoleId);

function listToArray(...args: ResourceType[]) {
  return [...args];
}

export interface DashboardRole {
  roleId: DashboardRoleId;
  name: string;
  description: string;
  resources: Partial<Record<ActionType, ResourceType[]>>;
}

export const DashboardRoles: Record<DashboardRoleId, DashboardRole> = {
  [DashboardRoleId.Admin]: {
    roleId: DashboardRoleId.Admin,
    name: 'Administrator',
    description: '',
    resources: {admin: Utilities.getEnumValues(ResourceType)}
  },
  [DashboardRoleId.Finance]: {
    roleId: DashboardRoleId.Finance,
    name: 'Finance',
    description: '',
    resources: {create: [ResourceType.Finance]}
  },
  [DashboardRoleId.Growth]: {
    roleId: DashboardRoleId.Growth,
    name: 'Growth / UA',
    description: '',
    resources: {create: [ResourceType.Finance]}
  },
  [DashboardRoleId.Developer]: {
    roleId: DashboardRoleId.Developer,
    name: 'Developer',
    description: '',
    resources: {create: [ResourceType.GameExperiments, ResourceType.GameVariables], update: [ResourceType.Games, ResourceType.GameIntegrations]}
  },
  [DashboardRoleId.AppManager]: {
    roleId: DashboardRoleId.AppManager,
    name: 'App Manager',
    description: '',
    resources: {create: [ResourceType.Games]}
  },
  [DashboardRoleId.Analyst]: {
    roleId: DashboardRoleId.Analyst,
    name: 'Data Analyst',
    description: '',
    resources: {
      create: [ResourceType.Data],
      list: [ResourceType.Growth, ResourceType.Games],
    }
  },
  [DashboardRoleId.QA]: {
    roleId: DashboardRoleId.QA,
    name: 'Quality Assurance',
    description: '',
    resources: {read: [ResourceType.Games]}
  },
  [DashboardRoleId.CS]: {
    roleId: DashboardRoleId.CS,
    name: 'Customer Support',
    description: '',
    resources: {read: [ResourceType.Games]}
  }
};

///////////////////////////////////////////
//
// Dashboard User
//

export type DashboardUserStatus = 'invited' | 'active' | 'suspended' | 'inactive' | 'disabled';

export interface DashboardUser {
  id: string;
  name: string;
  email: string;
  status: DashboardUserStatus;
  roles: DashboardRoleId[],
  applications: string[],

  created_date?: string,
  created_by?: string,
  last_updated_date?: string,
  last_updated_by?: string,
}

export interface InviteDashboardUserRequest extends Omit<DashboardUser, 'id'|'status'|'dateCreated'|'dateLastUpdated'|'createdBy'|'lastModifiedBy' > {
}

export interface GetDashboardUserRequest {
  userId: string;
}

export interface GetDashboardUserResponse {
  user?: DashboardUser;
}

export interface UpdateDashboardUserRequest {
  userId: string;
  properties: Partial<Omit<DashboardUser, 'userId'>>;
}

export interface DeleteDashboardUserRequest {
  userId: string;
}
