import { Component } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {Application} from "../../../wild-cloud/wild-cloud-models";
import {DashboardUser} from "../../../../../wildcard-dashboard-common/src/models/dashboard-user";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'applications-cell-renderer',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, MatTooltipModule],
  templateUrl: './applications-cell-renderer.component.html',
  styleUrls: ['./applications-cell-renderer.component.scss']
})
export class ApplicationsCellRendererComponent implements ICellRendererAngularComp {
  user: DashboardUser;
  games: Application[];

  agInit(params: ICellRendererParams<DashboardUser>): void {
    this.update(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.update(params);

    return true;
  }

  get isAllApps() {
    return this.user.applications.length === 0;
  }

  getIconUrl(appId: string): string {
    // console.log(appId);
    // console.log(this.games);
    return this.games.find(game => game.id === appId).iconUrl;
  }

  getAppName(appId: string): string {
    return this.games.find(game => game.id === appId).name;
  }

  update(params: any) {
    const {environment, data: variable, value: comparison} = params as any;
    // console.log(params);

    this.user = params.data;
    this.games = params.games;
  }
}
