import { Injectable } from '@angular/core';
import * as API from '@aws-amplify/api-rest'
import {RestApiOptionsBase} from "@aws-amplify/api-rest/src/types";
import {AnalyticsService} from "./analytics.service";

function contentLength(response) {
  return parseInt(response?.headers['content-length']);
}

export class ApiWrapper
{
  constructor(private apiName: string, private basePath: string = '', private analyticsService: AnalyticsService = null) {}

  async get<T>(path: string, options?: RestApiOptionsBase | undefined){
    const start = Date.now();

    options = options || {};

    if (this.basePath) {
      path = `${this.basePath}/${path}`;
    }

    const response = await API.get({apiName: this.apiName, path, options}).response;

    const r = await response.body.json() as T;

    if (this.analyticsService) {
      const duration = Date.now() - start;

      this.analyticsService.trackApi(this.apiName, 'get', path, duration);
    }

    return r;
  }
  async post<T>(path: string, options: RestApiOptionsBase){
    try {
      const response = await API.post({apiName: this.apiName, path, options}).response;

      if (contentLength(response) === 0) {
        return;
      }

      return await response.body.json() as T;
    } catch (err) {
      throw new Error(`Failed to execute post on ${this.apiName}. ${err}`);
    }
  }
  async put<T>(path: string, options: RestApiOptionsBase){
    const response = await API.put({apiName: this.apiName, path, options}).response;

    console.log('yo')
    console.warn(response.headers);

    if (contentLength(response) === 0) {
      return;
    }

    return await response.body.json() as T;
  }
  async del(path: string, options?: RestApiOptionsBase){
    return API.del({apiName: this.apiName, path, options}).response;
  }
  async head(path: string, options?: RestApiOptionsBase){
    return API.head({apiName: this.apiName, path, options}).response;
  }
  async patch<T>(path: string, options: RestApiOptionsBase){
    const response = await API.patch({apiName: this.apiName, path, options}).response;

    if (contentLength(response) === 0) {
      return;
    }

    return await response.body.json() as T;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {
  data: ApiWrapper;
  quicksightDashboard: ApiWrapper;
  reportingQuery: ApiWrapper;
  campaigns: ApiWrapper;
  athena: ApiWrapper;

  constructor(private analyticsService: AnalyticsService) {
    this.data = new ApiWrapper('data', '', analyticsService);
    this.quicksightDashboard = new ApiWrapper('quicksightDashboard', '', analyticsService);
    this.reportingQuery = new ApiWrapper('reportingQuery', '', analyticsService);
    this.campaigns = new ApiWrapper('campaigns', '', analyticsService);
    this.athena = new ApiWrapper('athena', '', analyticsService);
  }
}
